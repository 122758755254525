import { clsx } from 'clsx'
import { Button, CaretDownIcon, CaretUpIcon } from '@tovala/component-library'
import {
  Listing,
  MealSummary,
  TermStatusSubTerm,
  useUserTermStatuses,
} from '@tovala/browser-apis-combinedapi'
import { ReactNode, useEffect, useState } from 'react'

import { formatCentsToDollars } from 'utils/currency'
import { getMealImageURL } from 'utils/meals'
import { UserTerm } from 'types/internal'

import { useUser } from 'contexts/user'
import Collapsible, {
  CollapsibleContent,
  CollapsibleTrigger,
} from 'components/common/Collapsible'
import ErrorDisplay from 'components/common/ErrorDisplay'
import Sidebar, { SidebarBody, SidebarHeader } from 'components/common/Sidebar'
import { SmallMealImage } from 'components/common/MealImage'
import TermSkippedSidebarBody from '../TermSkippedSidebarBody'
import TermSoldOutSidebarBody from '../TermSoldOutSIdebarBody'
import { track } from 'utils/analytics'
import { events, sourceIDs } from 'analytics/events'
import { getTotalCreditsCents } from 'utils/user'

interface EstimatedTotals {
  mealPlan: {
    cost: number
    isDefault: boolean
    maxSelections: number
    name: string
  }
  shipping: number
  surcharge: number
  total: number
}

type OrderSummaryProps = {
  listings: ReactNode[]
  listingsTotalPriceCents: number
  meals: ReactNode[]
  onClickSkipAdjust(): void
  onCloseSidebar(): void
  orderStatus: ReactNode
  selectedUserTerm: UserTerm
  showOrderUpgrade: boolean
}

const OrderSummarySidebar = ({
  onCloseSidebar,
  selectedUserTerm,
  ...orderSummaryProps
}: OrderSummaryProps) => {
  return (
    <Sidebar onCloseSidebar={onCloseSidebar}>
      <SidebarHeader
        onClickClose={() => {
          track(events.ORDER_SUMMARY_DISMISS)
          onCloseSidebar()
        }}
      >
        Order Summary
      </SidebarHeader>

      <SidebarBody>
        <div className="space-y-10 px-6 pb-20 pt-10 md:space-y-4 md:px-4">
          {selectedUserTerm.isStaticSkipped ? (
            <TermSoldOutSidebarBody onClickMyOrders={onCloseSidebar} />
          ) : selectedUserTerm.isSkipped ? (
            <TermSkippedSidebarBody selectedUserTerm={selectedUserTerm} />
          ) : (
            <OrderSummary
              {...orderSummaryProps}
              onCloseSidebar={onCloseSidebar}
              selectedUserTerm={selectedUserTerm}
            />
          )}
        </div>
      </SidebarBody>
    </Sidebar>
  )
}

export default OrderSummarySidebar

const OrderSummary = ({
  listings,
  listingsTotalPriceCents,
  meals,
  onClickSkipAdjust,
  onCloseSidebar,
  orderStatus,
  selectedUserTerm,
  showOrderUpgrade,
}: OrderSummaryProps) => {
  const { selectedSubTerm } = selectedUserTerm || {}

  return (
    <>
      <div className="flex justify-end">
        <Button
          buttonStyle="stroke"
          disabled={showOrderUpgrade}
          onClick={() => {
            onClickSkipAdjust()
            track(events.SKIP_ADJUST_CTA, {
              source_id: sourceIDs.ORDER_SUMMARY,
            })
            onCloseSidebar()
          }}
          size="medium"
        >
          Skip/Adjust
        </Button>
      </div>

      {selectedSubTerm ? (
        <>
          <EstimatedTotal
            listingsTotalPriceCents={listingsTotalPriceCents}
            selectedSubTerm={selectedSubTerm}
            selectedUserTerm={selectedUserTerm}
          />

          <div>
            {meals.length === 0 ? (
              <div className="rounded-xl bg-white pb-16 pt-8 text-center">
                <p className="mx-auto max-w-[188px] text-k/20_110">
                  Choose meals or leave it to us!
                </p>
                <p className="mx-auto mt-4 max-w-[280px] text-sm text-grey-9">
                  Choose {selectedUserTerm.subscriptionType?.maxSelections}{' '}
                  meals from our menu to fill your box. Our chefs will add their
                  selections for any meals you do not choose.
                </p>
                <div className="mt-8 flex justify-center">
                  <Button
                    buttonStyle="dark"
                    onClick={() => {
                      track(events.ORDER_SUMMARY_PICK_MEALS)

                      onCloseSidebar()
                    }}
                    size="large"
                  >
                    Pick Meals
                  </Button>
                </div>
              </div>
            ) : (
              <div className="space-y-4">{meals}</div>
            )}

            {listings.length > 0 && (
              <div className="mt-4 border-t border-grey-3 pt-4">
                <div className="space-y-4">{listings}</div>
              </div>
            )}
          </div>

          {orderStatus}
        </>
      ) : (
        <ErrorDisplay
          helpToFix="Please refresh the page."
          why="We couldn't load your order summary details due to a technical issue on our end."
        />
      )}
    </>
  )
}

const EstimatedTotal = ({
  listingsTotalPriceCents,
  selectedSubTerm,
  selectedUserTerm,
}: {
  listingsTotalPriceCents: number
  selectedSubTerm: TermStatusSubTerm
  selectedUserTerm: UserTerm
}) => {
  const { user } = useUser()

  const isFirstAvailableUnskippedMenu = useIsFirstAvailableUnskippedMenu({
    termID: selectedUserTerm.termID,
    userID: user.id,
  })

  const { mealSelectionsPricing, orderDetails } = selectedSubTerm

  if (!mealSelectionsPricing || !orderDetails) {
    return null
  }

  const { mealsSurchargeAmountCents, shippingAmountCents } =
    mealSelectionsPricing
  const { mealAmountCents, totalAmountCents } = orderDetails

  const estimatedTotals: EstimatedTotals = {
    mealPlan: {
      cost: mealAmountCents,
      isDefault: selectedUserTerm.selectedSubscriptionTypeIsDefault,
      maxSelections: selectedUserTerm.subscriptionType?.maxSelections ?? 0,
      name: user.subscription.subscriptionType?.typeName ?? '',
    },
    shipping: shippingAmountCents,
    surcharge: mealsSurchargeAmountCents + listingsTotalPriceCents,
    total:
      mealsSurchargeAmountCents + totalAmountCents + listingsTotalPriceCents,
  }

  const discount = getTotalCreditsCents({ user })
  const showDiscount = isFirstAvailableUnskippedMenu && discount > 0
  const totalWithDiscount =
    discount < estimatedTotals.total ? estimatedTotals.total - discount : 0
  const orderDiscount =
    totalWithDiscount === 0 ? estimatedTotals.total : discount

  return (
    <div className="rounded-lg bg-grey-2">
      <Collapsible>
        {({ open }) => {
          return (
            <>
              <CollapsibleTrigger
                className="w-full p-6 md:px-4"
                onClick={() => {
                  if (!open) {
                    track(events.ORDER_SUMMARY_TOTAL_EXPAND)
                  }
                }}
              >
                <EstimatedTotalTrigger isOpen={open}>
                  {showDiscount && (
                    <span className="text-green">
                      {totalWithDiscount
                        ? `${formatCentsToDollars(totalWithDiscount)}`
                        : 'Free'}{' '}
                    </span>
                  )}
                  <span
                    className={clsx({
                      'line-through': showDiscount,
                    })}
                  >
                    {formatCentsToDollars(estimatedTotals.total)}
                  </span>
                </EstimatedTotalTrigger>
              </CollapsibleTrigger>

              <CollapsibleContent open={open}>
                <div className="px-6 pb-6 md:px-4">
                  <EstimatedTotalDetails
                    discount={showDiscount ? orderDiscount : 0}
                    estimatedTotals={estimatedTotals}
                  />
                </div>
              </CollapsibleContent>
            </>
          )
        }}
      </Collapsible>
    </div>
  )
}

const EstimatedTotalTrigger = ({
  children,
  isOpen,
}: {
  children: ReactNode
  isOpen: boolean
}) => {
  return (
    <div className="flex items-center justify-between text-k/20_125 md:text-k/18_120">
      <div className="flex items-center justify-between space-x-2">
        <h3>Estimated Total</h3>

        {isOpen ? (
          <div className="h-6 w-6">
            <CaretUpIcon />
          </div>
        ) : (
          <div className="h-6 w-6">
            <CaretDownIcon />
          </div>
        )}
      </div>

      <div>{children}</div>
    </div>
  )
}

const EstimatedTotalDetails = ({
  discount,
  estimatedTotals,
}: {
  discount: number
  estimatedTotals: EstimatedTotals
}) => {
  return (
    <>
      <div className="text-k/16_125">
        <div className="flex items-center justify-between border-t border-grey-4 py-6">
          <p className="flex items-center space-x-2 text-grey-8">
            <span
              style={{
                textDecoration: estimatedTotals.mealPlan.isDefault
                  ? ''
                  : 'line-through',
              }}
            >
              {estimatedTotals.mealPlan.name}
            </span>

            {!estimatedTotals.mealPlan.isDefault && (
              <span>{estimatedTotals.mealPlan.maxSelections} Meals</span>
            )}
          </p>

          {estimatedTotals.mealPlan.cost && (
            <span>{formatCentsToDollars(estimatedTotals.mealPlan.cost)}</span>
          )}
        </div>

        {estimatedTotals.surcharge > 0 && (
          <div>
            <div className="flex items-center justify-between pb-3">
              <span className="text-grey-8">Surcharges</span>
              <span>+{formatCentsToDollars(estimatedTotals.surcharge)}</span>
            </div>
            <p className="pb-6 text-k/12_120 text-grey-8">
              Surcharges include Meals that cost over $12.99, Two-Pack Meals,
              and any Extras ordered.
            </p>
          </div>
        )}

        <div className="flex items-center justify-between border-t border-grey-4 py-6">
          <span className="text-grey-8">Shipping</span>
          <span>
            {estimatedTotals.shipping
              ? `${formatCentsToDollars(estimatedTotals.shipping)}`
              : 'Free'}
          </span>
        </div>

        {discount > 0 && (
          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <span className="text-grey-8">Discount</span>
              <span className="text-green">
                -{formatCentsToDollars(discount)}
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export const OrderSummaryExtra = ({
  listing,
  stepper,
}: {
  listing: Listing
  stepper: ReactNode
}) => {
  return (
    <OrderSummaryItem
      imageURL={listing.imageURL}
      price={`${formatCentsToDollars(listing.priceCents)}`}
      stepper={stepper}
      title={listing.title}
    />
  )
}

export const OrderSummaryMeal = ({
  meal,
  mealStepper,
}: {
  meal: MealSummary
  mealStepper: ReactNode
}) => {
  return (
    <OrderSummaryItem
      imageURL={getMealImageURL(meal)}
      price={
        meal.surchargeCents > 0
          ? `+${formatCentsToDollars(meal.surchargeCents)}`
          : ''
      }
      stepper={mealStepper}
      subtitle={meal.subtitle}
      title={meal.title}
    />
  )
}

const OrderSummaryItem = ({
  imageURL,
  price,
  stepper,
  subtitle,
  title,
}: {
  imageURL: string
  price?: string
  stepper: ReactNode
  subtitle?: string
  title: string
}) => {
  return (
    <div className="flex items-center justify-between space-x-4">
      <div className="flex items-center space-x-4">
        <div className="shrink-0">
          <SmallMealImage imageURL={imageURL} />
        </div>

        <div className="space-y-1">
          <h3 className="text-k/16_125 md:text-k/14_120">{title}</h3>
          {subtitle && (
            <p className="text-k/13_120 text-grey-10 md:text-k/12_120">
              {subtitle}
            </p>
          )}
          {price && (
            <p className="text-k/13_120 text-orange-1 md:text-k/12_120">
              {price}
            </p>
          )}
        </div>
      </div>

      <div className="flex shrink-0 justify-end">{stepper}</div>
    </div>
  )
}

function useIsFirstAvailableUnskippedMenu({
  termID,
  userID,
}: {
  termID: number
  userID: number
}) {
  const { data: termStatuses = [] } = useUserTermStatuses({ userID })

  const firstUnskippedTermID = termStatuses.find(
    (term) => !term.isSkipped
  )?.termID

  return termID === firstUnskippedTermID
}

export interface OrderSummarySidebarStatus {
  isOpen: boolean
  openSource: 'selectionsConfirmation' | undefined
}

export function useOrderSummarySidebar({
  hasSelectedAllMeals,
}: {
  hasSelectedAllMeals: boolean
}) {
  // We keep track of where order summary was opened from for sources where we need
  // some special logic, like showing the "My Orders" button on the coaching bar
  // when summary was opened from the selections confirmed dialog so the user can't
  // get stuck in a loop of opening summary and seeing the confirmation dialog
  const [sidebarStatus, setSidebarStatus] = useState<OrderSummarySidebarStatus>(
    {
      isOpen: false,
      openSource: undefined,
    }
  )

  function closeOrderSummary() {
    setSidebarStatus((prevState) => {
      return {
        isOpen: false,
        openSource: prevState.openSource,
      }
    })
  }

  function openOrderSummary(
    openSource: OrderSummarySidebarStatus['openSource'] = undefined
  ) {
    setSidebarStatus((prevState) => {
      return {
        isOpen: true,
        openSource:
          openSource !== undefined ? openSource : prevState.openSource,
      }
    })
  }

  useEffect(() => {
    if (!hasSelectedAllMeals) {
      setSidebarStatus((prevState) => {
        return {
          isOpen: prevState.isOpen,
          openSource: undefined,
        }
      })
    }
  }, [hasSelectedAllMeals])

  return {
    closeOrderSummary,
    openOrderSummary,
    orderSummarySidebarStatus: sidebarStatus,
  }
}
