import invariant from 'tiny-invariant'

type EnvVars = {
  APP_ENV: 'development' | 'develop-preview' | 'preview' | 'production' | 'test'
  BUY_URL: string
  CDN_URL: string
  COMBINED_API_URL: string
  GOOGLE_MAPS_API_KEY: string
  JWT_COOKIE_NAME: string
  SEGMENT_WRITE_KEY: string
  SENTRY_DSN: string
  SENTRY_ORG: string
  STRIPE_KEY: string
  VERCEL_GIT_COMMIT_SHA: string
}

const ALLOWED_APP_ENVS = [
  'development',
  'develop-preview',
  'preview',
  'production',
  'test',
] as const

function isAllowedAppEnv(appEnv: string): appEnv is EnvVars['APP_ENV'] {
  return ALLOWED_APP_ENVS.includes(appEnv as EnvVars['APP_ENV'])
}

invariant(
  import.meta.env.VITE_APP_ENV && isAllowedAppEnv(import.meta.env.VITE_APP_ENV),
  `APP_ENV must be one of: ${ALLOWED_APP_ENVS.join(', ')}`
)
invariant(import.meta.env.VITE_BUY_URL, 'BUY_URL must be set')
invariant(import.meta.env.VITE_CDN_URL, 'CDN_URL must be set')
invariant(import.meta.env.VITE_COMBINED_API_URL, 'COMBINED_API_URL must be set')
invariant(
  import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
  'GOOGLE_MAPS_API_KEY must be set'
)
invariant(import.meta.env.VITE_JWT_COOKIE_NAME, 'JWT_COOKIE_NAME must be set')
invariant(
  import.meta.env.VITE_SEGMENT_WRITE_KEY,
  'SEGMENT_WRITE_KEY must be set'
)
invariant(import.meta.env.VITE_STRIPE_KEY, 'STRIPE_KEY must be set')

const ENV_VARS: {
  [key in keyof EnvVars]: EnvVars[key]
} = {
  APP_ENV: import.meta.env.VITE_APP_ENV,
  BUY_URL: import.meta.env.VITE_BUY_URL,
  CDN_URL: import.meta.env.VITE_CDN_URL,
  COMBINED_API_URL: import.meta.env.VITE_COMBINED_API_URL,
  GOOGLE_MAPS_API_KEY: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
  JWT_COOKIE_NAME: import.meta.env.VITE_JWT_COOKIE_NAME,
  SEGMENT_WRITE_KEY: import.meta.env.VITE_SEGMENT_WRITE_KEY,
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN ?? '',
  SENTRY_ORG: import.meta.env.VITE_SENTRY_ORG ?? '',
  STRIPE_KEY: import.meta.env.VITE_STRIPE_KEY,
  VERCEL_GIT_COMMIT_SHA: import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA ?? '',
}

export const TOVALA_APIS = [ENV_VARS.COMBINED_API_URL]

export function getEnvVar<EnvVar extends keyof EnvVars>(
  envVar: EnvVar
): EnvVars[EnvVar] {
  return ENV_VARS[envVar]
}
