import {
  useInvalidateUsers,
  UserV1Subscription,
  useInvalidateListings,
} from '@tovala/browser-apis-combinedapi'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import {
  changeSubscriptionStatus,
  ChangeSubscriptionStatus,
  updatePlanPreferences,
  UpdatePlanPreferences,
} from 'services/combinedAPI/subscriptions'
import { events } from 'analytics/events'
import { isCombinedAPIResponseError } from 'utils/api'
import { track } from 'utils/analytics'

import { useInvalidateTermStatuses } from './termStatus'

export function useChangeSubscriptionStatus(
  opts?: Omit<
    UseMutationOptions<
      unknown,
      Error,
      ChangeSubscriptionStatus & { reactivationDate?: string }
    >,
    'mutationFn'
  >
) {
  const { invalidateUserTermStatuses } = useInvalidateTermStatuses()
  const { invalidateUserV0, invalidateUserV1 } = useInvalidateUsers()
  const { invalidateAllListingSelections } = useInvalidateListings()

  return useMutation({
    ...opts,
    mutationFn: ({ data, subscriptionStatus, userID }) => {
      return changeSubscriptionStatus({ data, subscriptionStatus, userID })
    },
    onError: (...args) => {
      const err = args[0]
      const { userID } = args[1]

      if (
        isCombinedAPIResponseError(err) &&
        err.response?.data.message ===
          'UnableToUpdateSubscriptionToExistingState'
      ) {
        invalidateUserV0(userID)
        invalidateUserV1(userID)
      }

      opts?.onError?.(...args)
    },
    onSuccess: (...args) => {
      const { subscriptionStatus, userID } = args[1]

      if (subscriptionStatus === 'pause') {
        track(events.PAUSES_MEAL_PLAN)
      } else if (subscriptionStatus === 'cancel') {
        track(events.CANCELS_MEAL_PLAN)
      }

      invalidateUserV0(userID)
      invalidateUserV1(userID)
      invalidateUserTermStatuses(userID)
      invalidateAllListingSelections(userID)

      opts?.onSuccess?.(...args)
    },
  })
}

export function useUpdatePlanPreferences(
  opts?: Omit<
    UseMutationOptions<UserV1Subscription, Error, UpdatePlanPreferences>,
    'mutationFn'
  >
) {
  const { invalidateUserV1 } = useInvalidateUsers()

  return useMutation({
    ...opts,
    mutationFn: ({ data, userID }) => {
      return updatePlanPreferences({ data, userID })
    },
    onError: (...args) => {
      opts?.onError?.(...args)
    },
    onSuccess: (...args) => {
      const { userID } = args[1]

      invalidateUserV1(userID, { newSubscription: args[0] })

      opts?.onSuccess?.(...args)
    },
  })
}
