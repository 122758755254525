import { ButtonLoading } from '@tovala/component-library'
import {
  useInvalidateTermStatuses,
  useUnskipWeek,
} from '@tovala/browser-apis-combinedapi'

import { ErrorCodeMessageMapCombinedAPI, UserTerm } from 'types/internal'
import { track } from 'utils/analytics'
import { events, sourceIDs } from 'analytics/events'

import APIErrorDisplay from 'components/common/APIErrorDisplay'

const UNSKIP_WEEK_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please try again.',
    why: "We couldn't unskip this delivery due to a technical issue on our end.",
  },
}

const TermSkippedSidebarBody = ({
  selectedUserTerm,
}: {
  selectedUserTerm: UserTerm
}) => {
  const { invalidateUserTermStatuses } = useInvalidateTermStatuses()

  const {
    error: unskipError,
    isError: hasUnskipError,
    isLoading: isUnskippingWeek,
    mutate: unskipWeek,
  } = useUnskipWeek()

  return (
    <div className="flex flex-col items-center space-y-4">
      <div className="text-center">
        <p>
          <strong>You have skipped this delivery.</strong>
        </p>
        <p>
          If you'd like to receive meals, please use the "Unskip" button below.
        </p>
      </div>

      {hasUnskipError && (
        <APIErrorDisplay
          error={unskipError}
          errorCodeMessageMap={UNSKIP_WEEK_ERRORS}
        />
      )}

      <ButtonLoading
        isLoading={isUnskippingWeek}
        onClick={() => {
          unskipWeek(
            {
              data: {
                termid: selectedUserTerm.termID,
              },
              userID: selectedUserTerm.userID,
            },
            {
              onSuccess: () => {
                invalidateUserTermStatuses(selectedUserTerm.userID)

                track(events.UNSKIPS_WEEK, {
                  source_id: sourceIDs.MENU_TERM,
                  term_id: selectedUserTerm.termID,
                })
              },
            }
          )
        }}
        size="large"
      >
        Unskip
      </ButtonLoading>
    </div>
  )
}

export default TermSkippedSidebarBody
