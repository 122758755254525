import { getUserTerm } from 'utils/terms'

import {
  GetUserTermStatusesResponse,
  useSkippedWeeks,
  useUserTermStatuses,
} from '@tovala/browser-apis-combinedapi'
import { useUser } from 'contexts/user'
import { UseQueryOptions } from '@tanstack/react-query'

export function useSelectedUserTerm({
  selectedUserTermID,
}: {
  selectedUserTermID: number | null | undefined
}) {
  const { user } = useUser()

  const {
    data: termStatuses = [],
    error: loadUserTermStatusesError,
    isError: hasLoadUserTermStatusesError,
    isLoading: isLoadingUserTermStatuses,
  } = useUserTermStatuses({ userID: user.id })

  const { data: skippedWeeks, isLoading: isLoadingSkippedWeeks } =
    useSkippedWeeks({
      userID: user.id,
    })

  const selectedTerm = termStatuses.find(
    (term) => term.termID === selectedUserTermID
  )

  return {
    error: hasLoadUserTermStatusesError ? loadUserTermStatusesError : null,
    isLoading: isLoadingUserTermStatuses || isLoadingSkippedWeeks,
    selectedUserTerm: selectedTerm
      ? getUserTerm({ skippedWeeks, term: selectedTerm, user })
      : undefined,
  }
}

export function useUserNextAvailableTermID({
  userID,
  ...opts
}: {
  userID: number | undefined
} & UseQueryOptions<GetUserTermStatusesResponse, Error>) {
  const { data, error, isError, isLoading } = useUserTermStatuses({
    ...opts,
    userID,
  })

  return {
    data: data ? data[0].termID : undefined,
    error,
    isError,
    isLoading,
  }
}
