import { APIErrorCode as APIErrorCodeCombinedAPI } from '@tovala/browser-apis-combinedapi'
import { AxiosError } from 'axios'

import { APIErrorResponse } from 'types/domainModels/shared'
import { ErrorCodeMessageMap, GoodErrorMessaging } from 'types/internal'

export function getAPIErrorCode<ErrorCodes extends string>({
  error,
}: {
  error: Error | null
}) {
  return isAxiosResponseError<ErrorCodes>(error)
    ? error.response?.data.message
    : undefined
}

export function getAPIErrorMessage<ErrorCodes extends string>({
  error,
  errorCodeMessageMap,
}: {
  error: Error | null
  errorCodeMessageMap: ErrorCodeMessageMap<ErrorCodes>
}): GoodErrorMessaging {
  const key = getAPIErrorCode<ErrorCodes>({ error })

  const errorMessaging = key ? errorCodeMessageMap[key] : undefined

  if (errorMessaging) {
    return errorMessaging
  } else if ('Fallback' in errorCodeMessageMap) {
    return errorCodeMessageMap.Fallback
  }

  return { why: 'You action failed due to a technical issue on our end.' }
}

export const getCombinedAPIErrorCode = getAPIErrorCode<APIErrorCodeCombinedAPI>

export function isAxiosResponseError<ErrorCodes extends string>(
  error: Error | null | undefined
): error is AxiosError<APIErrorResponse<ErrorCodes>> {
  return (error as AxiosError)?.response !== undefined
}

export const isCombinedAPIResponseError =
  isAxiosResponseError<APIErrorCodeCombinedAPI>

export function isResponseStatusCode({
  error,
  statusCode,
}: {
  error: Error
  statusCode: number
}) {
  return isAxiosResponseError(error)
    ? error.response?.status === statusCode
    : false
}
