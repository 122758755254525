import {
  APIErrorDisplay,
  ButtonLoading,
  XIcon,
} from '@tovala/component-library'
import { Link } from 'react-router-dom'

import { useUser } from 'contexts/user'
import { ErrorCodeMessageMapCombinedAPI, UserTerm } from 'types/internal'
import { track } from 'utils/analytics'
import { events, sourceIDs } from 'analytics/events'
import {
  useInvalidateTermStatuses,
  useUnskipWeek,
} from '@tovala/browser-apis-combinedapi'

const UNSKIP_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  UnableToDeleteStaticTermSkip: {
    helpToFix: 'Please select meals starting next week.',
    wayOut: (
      <span className="underline">
        <Link to="/my-orders">Click here to return to your orders.</Link>
      </span>
    ),
    why: "We couldn't unskip this week because we're sold out.",
  },
  Fallback: {
    helpToFix: 'Please try again.',
    why: "We couldn't unskip this week due to a technical issue on our end.",
  },
}

const SkippedOrderStatus = ({
  selectedUserTerm,
}: {
  selectedUserTerm: UserTerm
}) => {
  const { user } = useUser()

  const { invalidateUserTermStatuses } = useInvalidateTermStatuses()

  const {
    error: unskipError,
    isError: hasUnskipError,
    isLoading: isUnskippingWeek,
    mutate: unskipWeek,
  } = useUnskipWeek()

  return (
    <div className="space-y-4">
      {hasUnskipError && (
        <APIErrorDisplay
          error={unskipError}
          errorCodeMessageMap={UNSKIP_ERRORS}
        />
      )}
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-1">
          <div className="h-6 w-6">
            <XIcon color="red" />
          </div>

          <span className="text-k/20_110">Skipped</span>
        </div>

        {!selectedUserTerm.isStaticSkipped ? (
          <div className="h-11 w-[139px] text-k/14_120 md:h-9">
            <ButtonLoading
              isLoading={isUnskippingWeek}
              onClick={() => {
                unskipWeek(
                  {
                    data: {
                      termid: selectedUserTerm.termID,
                    },
                    userID: user.id,
                  },
                  {
                    onSuccess: () => {
                      invalidateUserTermStatuses(user.id)

                      track(events.UNSKIPS_WEEK, {
                        source_id: sourceIDs.MENU_TERM,
                        term_id: selectedUserTerm.termID,
                      })
                    },
                  }
                )
              }}
              size="fluid"
            >
              Unskip
            </ButtonLoading>
          </div>
        ) : (
          <div>Sold Out</div>
        )}
      </div>
    </div>
  )
}

export default SkippedOrderStatus
