import { Button } from '@tovala/component-library'
import {
  useMenuListingsLayout,
  usePublicTermStatuses,
} from '@tovala/browser-apis-combinedapi'
import { ReactNode } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ErrorCodeMessageMapCombinedAPI, UserTerm } from 'types/internal'

import {
  useSelectedUserTerm,
  useUserNextAvailableTermID,
} from 'hooks/combinedAPI/terms'
import { useUser } from 'contexts/user'
import APIErrorDisplay from 'components/common/APIErrorDisplay'
import CustomWebWidgetLauncher from './CustomWebWidgetLauncher'
import Loader from 'components/common/Loader'
import Menu from './Menu'
import PublicMenu from './PublicMenu'

const LOAD_MENU_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please reload the page to try again.',
    whatHappened: 'Unable to Load Menu',
    why: "We couldn't load the menu due to a technical issue on our end.",
  },
}

const MenuPage = ({
  isCurrentTerm,
  onEditTermStatus,
}: {
  isCurrentTerm?: boolean
  onEditTermStatus(selectedUserTerm: UserTerm): void
}) => {
  const { termID: termIDParam } = useParams()

  const termIDToView = termIDParam ? Number.parseInt(termIDParam, 10) : null

  const { user } = useUser()

  const isSubscriptionActive = user.subscription.status === 'active'

  return (
    <>
      {isSubscriptionActive ? (
        <LoadActiveSubscriptionMenu
          isCurrentTerm={isCurrentTerm}
          onEditTermStatus={onEditTermStatus}
          termIDToView={termIDToView}
        />
      ) : (
        <LoadPublicMenu
          isCurrentTerm={isCurrentTerm}
          termIDToView={termIDToView}
        />
      )}

      <CustomWebWidgetLauncher />
    </>
  )
}

export default MenuPage

const CenteredContent = ({ children }: { children: ReactNode }) => {
  return (
    <div className="mx-auto my-40 w-full max-w-lg px-6 md:px-4">{children}</div>
  )
}

const UnavailableMenu = () => {
  const navigate = useNavigate()
  return (
    <CenteredContent>
      <div className="text-center">
        <h1 className="text-k/36_110 md:text-k/28_110">
          This menu is unavailable
        </h1>

        <div className="mt-6 flex justify-center">
          <Button onClick={() => navigate('/my-orders')} size="large">
            My Orders
          </Button>
        </div>
      </div>
    </CenteredContent>
  )
}

const LoadPublicMenu = ({
  isCurrentTerm = false,
  termIDToView,
}: {
  isCurrentTerm?: boolean
  termIDToView: number | null
}) => {
  const {
    data: publicTermStatuses = [],
    error: loadPublicTermStatusesError,
    isError: hasLoadPublicTermStatusesError,
    isLoading: isLoadingPublicTermStatuses,
  } = usePublicTermStatuses()

  const selectedPublicTerm = isCurrentTerm
    ? publicTermStatuses[0]
    : publicTermStatuses.find((term) => term.termID === termIDToView)

  if (selectedPublicTerm) {
    return <PublicMenu selectedPublicTerm={selectedPublicTerm} />
  } else if (hasLoadPublicTermStatusesError) {
    return (
      <CenteredContent>
        <APIErrorDisplay
          display="page"
          error={loadPublicTermStatusesError}
          errorCodeMessageMap={LOAD_MENU_ERRORS}
        />
      </CenteredContent>
    )
  } else if (!isLoadingPublicTermStatuses) {
    return <UnavailableMenu />
  }

  return <Loader />
}

const LoadActiveSubscriptionMenu = ({
  isCurrentTerm = false,
  onEditTermStatus,
  termIDToView,
}: {
  isCurrentTerm?: boolean
  onEditTermStatus(selectedUserTerm: UserTerm): void
  termIDToView: number | null
}) => {
  const { user } = useUser()

  const {
    data: nextTermID,
    error: loadNextTermError,
    isError: hasLoadNextTermError,
    isLoading: isLoadingNextTerm,
  } = useUserNextAvailableTermID({ userID: user.id, enabled: isCurrentTerm })

  const isLoadingCurrentTerm = !!(isCurrentTerm && isLoadingNextTerm)

  const {
    error: loadSelectedUserTermError,
    isLoading: isLoadingUserTerm,
    selectedUserTerm,
  } = useSelectedUserTerm({
    selectedUserTermID: isCurrentTerm ? nextTermID : termIDToView,
  })

  const { data: menuListingsLayout, isLoading: isLoadingMenuListingsLayout } =
    useMenuListingsLayout({
      menuID: selectedUserTerm?.selectedSubTerm?.mainMenu.id,
    })

  if (isCurrentTerm && !termIDToView && hasLoadNextTermError) {
    return (
      <CenteredContent>
        <APIErrorDisplay
          display="page"
          error={loadNextTermError}
          errorCodeMessageMap={LOAD_MENU_ERRORS}
        />
      </CenteredContent>
    )
  }

  if (selectedUserTerm && menuListingsLayout) {
    return (
      <Menu
        menuListingsLayout={menuListingsLayout}
        onEditTermStatus={onEditTermStatus}
        selectedUserTerm={selectedUserTerm}
      />
    )
  } else if (loadSelectedUserTermError) {
    return (
      <CenteredContent>
        <APIErrorDisplay
          display="page"
          error={loadSelectedUserTermError}
          errorCodeMessageMap={LOAD_MENU_ERRORS}
        />
      </CenteredContent>
    )
  } else if (
    !isLoadingUserTerm &&
    !isLoadingCurrentTerm &&
    !isLoadingMenuListingsLayout
  ) {
    return <UnavailableMenu />
  }

  return <Loader />
}
