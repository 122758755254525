import { UserV1Subscription } from '@tovala/browser-apis-combinedapi'

import { put } from './baseAPI'
import { SubscriptionPreferences } from 'types/internal'

export interface ChangeSubscriptionStatus {
  data: { defaultShipPeriod: number; subscriptionTypeID: string }
  subscriptionStatus: 'activate' | 'cancel' | 'pause'
  userID: number
}

export interface UpdatePlanPreferences {
  data: SubscriptionPreferences
  userID: number
}

export const ENDPOINTS = {
  CHANGE_SUBSCRIPTION_STATUS: ({
    subscriptionStatus,
    userID,
  }: Pick<ChangeSubscriptionStatus, 'subscriptionStatus' | 'userID'>) => ({
    path: `/users/${userID}/subscription/${subscriptionStatus}`,
    version: 'v1' as const,
  }),
  UPDATE_PLAN_PREFERENCES: ({
    userID,
  }: Pick<UpdatePlanPreferences, 'userID'>) => ({
    path: `/users/${userID}/subscriptionPreferences`,
    version: 'v1' as const,
  }),
}

export async function changeSubscriptionStatus({
  data,
  subscriptionStatus,
  userID,
}: ChangeSubscriptionStatus): Promise<unknown> {
  return put({
    data,
    ...ENDPOINTS.CHANGE_SUBSCRIPTION_STATUS({ subscriptionStatus, userID }),
  })
}

export async function updatePlanPreferences({
  data,
  userID,
}: UpdatePlanPreferences): Promise<UserV1Subscription> {
  return put({
    data,
    ...ENDPOINTS.UPDATE_PLAN_PREFERENCES({ userID }),
  })
}
